import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const Clients = () => {
  const staticGraphData = [70, 30, 50, 20, 10];
  const totalLength = staticGraphData.reduce((a, b) => a + b, 0);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "donut",
    },
    labels: [
      "Active Clients",
      "Inactive Clients",
      "Pending Clients",
      "Freeze Clients",
      "Archive Clients",
    ],
    colors: [
      "rgba(23, 43, 115, 1)", // Full opacity (100%)
      "rgba(23, 43, 115, 0.8)", // 80% opacity
      "rgba(23, 43, 115, 0.6)", // 60% opacity
      "rgba(23, 43, 115, 0.4)", // 40% opacity
      "rgba(23, 43, 115, 0.2)", // 20% opacity
    ],
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) =>
          value !== undefined && value !== null ? value.toString() : "0",
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => {
        const calculatedValue = Math.round((value * totalLength) / 100);
        return calculatedValue !== undefined && calculatedValue !== null
          ? calculatedValue.toString()
          : "0";
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
  });

  return (
    <ReactApexChart
      options={chartOptions}
      series={staticGraphData}
      type="donut"
      height={350}
    />
  );
};

export default Clients;
