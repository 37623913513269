import React, { useState } from "react";
import Moment from "react-moment";
import { Form, Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
// import Pagination from "../../../../Hooks/Pagination";

const UserTableData = ({ onMouseDown, tableRef, setToggle1, setToggle }) => {
  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>User Id</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>User Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Email</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Authorities</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)}>Status</th>
            <th onMouseDown={(e) => onMouseDown(e, 7)}>Added Date | Time</th>
            <th onMouseDown={(e) => onMouseDown(e, 8)} style={{ width: 80 }}>
              <div className="text-center">Update</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 9)} style={{ width: 60 }}>
              <div className="text-center">Remove</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={`position-relative cursor-pointer `}>
            <td onClick={() => setToggle1()}>1</td>
            <td onClick={() => setToggle1()}>user12</td>
            <td onClick={() => setToggle1()}>John Deo</td>
            <td onClick={() => setToggle1()}>john@company.com</td>
            <td onClick={() => setToggle1()}>Data Management</td>
            <td className="p-0">
              <Form.Select
                className={`border-0 rounded-0 bg-transparent `}
                style={{ fontSize: 12 }}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </td>
            <td onClick={() => setToggle1()}>
              <Moment format="DD MMM YYYY | HH:mm:ss">
                12/12/2024 | 12:12
              </Moment>
            </td>
            <td className="text-center">
              <div title="Update" onClick={() => setToggle()}>
                <FiEdit className="text-primary" />
              </div>
            </td>
            <td>
              <div className="text-center cursor-pointer" title="Delete User">
                <i className={`fa-solid fa-trash-can CP red text-danger`}></i>
              </div>
            </td>
          </tr>
          <tr className={`position-relative cursor-pointer `}>
            <td onClick={() => setToggle1()}>1</td>
            <td onClick={() => setToggle1()}>user1233</td>
            <td onClick={() => setToggle1()}>John Deo</td>
            <td onClick={() => setToggle1()}>john@company.com</td>
            <td onClick={() => setToggle1()}>User Management</td>
            <td className="p-0">
              <Form.Select
                className={`border-0 rounded-0 bg-transparent `}
                style={{ fontSize: 12 }}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </td>
            <td onClick={() => setToggle1()}>
              <Moment format="DD MMM YYYY | HH:mm:ss">
                12/12/2024 | 12:12
              </Moment>
            </td>
            <td className="text-center">
              <div title="Update" onClick={() => setToggle()}>
                <FiEdit className="text-primary" />
              </div>
            </td>
            <td>
              <div className="text-center cursor-pointer" title="Delete User">
                <i className={`fa-solid fa-trash-can CP red text-danger`}></i>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      {/* <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={addUserData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div> */}
    </>
  );
};

export default UserTableData;
