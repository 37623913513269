import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";

const AddAccessManagementForm = ({ createSponserId, Show, Hide, Title }) => {
  const [accessManagementName, setAccessManagementName] = useState("");
  const [accessManagementDescription, setAccessManagementDescription] = useState("");
  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Access Management Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              value={accessManagementName}
              onChange={(e) => setAccessManagementName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Access Management Description <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter Description"
              value={accessManagementDescription}
              onChange={(e) => setAccessManagementDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="text-end">
            <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
              <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
              {!createSponserId ? "Add" : "Update"} Access Management
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddAccessManagementForm;
