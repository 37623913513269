import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { BsBuildingCheck } from "react-icons/bs";
import { FaBookReader, FaFolderOpen, FaUserCheck } from "react-icons/fa";
import Clients from "./Chart/Clients";
import Users from "./Chart/Users";
import Projects from "./Chart/Projects";
import Studies from "./Chart/Studies";

const Dashboard = () => {
  return (
    <>
      <div className="py-2">
        <Container fluid>
          <Row className="chart__overview">
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <BsBuildingCheck className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>170</Card.Title>
                      <Card.Text>Total Clients</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUserCheck className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>150</Card.Title>
                      <Card.Text>Total User</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaFolderOpen className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>20</Card.Title>
                      <Card.Text>Total Project</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaBookReader className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>45</Card.Title>
                      <Card.Text>Total Study</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 400 }} className="text-center">
                  <Card.Text>Client Summery</Card.Text>
                  <Clients />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 400 }} className="text-center">
                  <Card.Text>User Summery</Card.Text>
                  <Users />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 400 }} className="text-center">
                  <Card.Text>Project Summery</Card.Text>
                  <Projects />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 400 }} className="text-center">
                  <Card.Text>Study Summery</Card.Text>
                  <Studies />
                </Card.Body>
              </Card>
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
