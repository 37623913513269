import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import UseToggle from "../../../Hooks/UseToggle";
import AddAccessManagementForm from "./AddAccessManagementForm";
import AccessManagementTableData from "./AccessManagementTableData";
import { Col, Container, Form, Row } from "react-bootstrap";

const AccessManagement = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle } = UseToggle();

  const HeaderTools = [
    {
      title: "Add Access Management",
      icon: <AiOutlinePlus />,
      onClick: setToggle,
    },
  ];

  return (
    <>
      <div className={`code-list-container`}>
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            {HeaderTools?.map((button, index) => (
              <React.Fragment key={index}>
                <button
                  title={button.title}
                  className="tabButtons border rounded-1 fs-13"
                  style={{ padding: "1px 5px" }}
                  onClick={button.onClick}
                >
                  {button.icon}
                </button>
              </React.Fragment>
            ))}
          </div>
        </div>
        <Container fluid>
          <Row>
            <Form.Group as={Col} md={7} className="p-0">
              <div className="p-2">
                <AccessManagementTableData
                  setToggle={setToggle}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                />
              </div>
            </Form.Group>
            <Form.Group as={Col} md={5} className="p-0">
              <div
                className="p-2 overflow-auto border-start"
                style={{ height: "calc(100vh - 146px)" }}
              >
                <Form.Group className="px-3">
                  <Form.Label className="fw-bold" style={{ fontSize: 14 }}>
                    <u> User Management</u>
                  </Form.Label>
                  <Row>
                    <Form.Group as={Col} md={12}>
                      <Form.Check
                        type="checkbox"
                        label="Create & Edit User"
                        name="accessCheck"
                        inline
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Check
                        type="checkbox"
                        label="Read Only User"
                        name="accessCheck"
                        inline
                      />
                    </Form.Group>
                  </Row>
                  <Form.Label className="fw-bold mt-3" style={{ fontSize: 14 }}>
                    <u> Client Management</u>
                  </Form.Label>
                  <Row>
                    <Form.Group as={Col} md={12}>
                      <Form.Check
                        type="checkbox"
                        label="Create & Edit Client"
                        name="accessCheck"
                        inline
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Check
                        type="checkbox"
                        label="Read Only Client"
                        name="accessCheck"
                        inline
                      />
                    </Form.Group>
                  </Row>
                </Form.Group>
              </div>
            </Form.Group>
          </Row>
        </Container>
      </div>

      <AddAccessManagementForm
        Show={!toggle}
        Hide={setToggle}
        Title={"Add Access Management"}
      />
    </>
  );
};

export default AccessManagement;
