import React, { useState } from "react";
import Moment from "react-moment";
import { Form, Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
// import Pagination from "../../../../Hooks/Pagination";

const UserTableData = ({ onMouseDown, tableRef, setToggle }) => {
  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Management name</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Description</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 80 }}>
              <div className="text-center">Update</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 5)} style={{ width: 60 }}>
              <div className="text-center">Remove</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={`position-relative cursor-pointer `}>
            <td>1</td>
            <td>Data Management</td>
            <td>Data Description</td>
            <td className="text-center">
              <div title="Update" onClick={() => setToggle()}>
                <FiEdit className="text-primary" />
              </div>
            </td>
            <td>
              <div className="text-center cursor-pointer" title="Delete User">
                <i className={`fa-solid fa-trash-can CP red text-danger`}></i>
              </div>
            </td>
          </tr>
          <tr className={`position-relative cursor-pointer `}>
            <td>1</td>
            <td>User Management</td>
            <td>User Description</td>
            <td className="text-center">
              <div title="Update" onClick={() => setToggle()}>
                <FiEdit className="text-primary" />
              </div>
            </td>
            <td>
              <div className="text-center cursor-pointer" title="Delete">
                <i className={`fa-solid fa-trash-can CP red text-danger`}></i>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      {/* <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={addUserData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div> */}
    </>
  );
};

export default UserTableData;
