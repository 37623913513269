import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import UseToggle from "../../../../Hooks/UseToggle";
import AddClientForm from "./AddClientForm";
import ClientTableData from "./ClientTableData";
import ClientAttributeData from "./ClientAttributeData";
import { CiExport } from "react-icons/ci";

const ClientData = ({ onMouseDown, tableRef, handleAddNewTab }) => {
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [phone, setPhone] = useState("");
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [city_id, setCityID] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [Country, setCountry] = useState([]);
  const [loader, setLoader] = useState(false);
  const [state_id, setStateID] = useState("");
  const [shortName, setShortName] = useState("");
  const [country_id, setCountryID] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const HeaderTools = [
    {
      title: "Add Client",
      icon: <AiOutlinePlus />,
      onClick: setToggle,
    },
  ];

  return (
    <>
      <div className={`code-list-container`}>
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            {HeaderTools?.map((button, index) => (
              <React.Fragment key={index}>
                <button
                  title={button.title}
                  className="tabButtons border rounded-1 fs-13"
                  style={{ padding: "1px 5px" }}
                  onClick={button.onClick}
                >
                  {button.icon}
                </button>
              </React.Fragment>
            ))}
          </div>
          <button className="tabButtons border rounded-1 fs-13" title="Export">
            <CiExport />
          </button>
        </div>
        <div className="position-relative p-2">
          <ClientTableData
            setToggle={setToggle}
            setToggle1={setToggle1}
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            handleAddNewTab={handleAddNewTab}
          />
        </div>
      </div>

      <ClientAttributeData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"View Client Detail"}
        tableRef={tableRef}
        onMouseDown={onMouseDown}
      />
      <AddClientForm
        Show={!toggle}
        Hide={setToggle}
        Title={"Add Client"}
        companyName={companyName}
        setCompanyName={setCompanyName}
        shortName={shortName}
        setShortName={setShortName}
        website={website}
        setWebsite={setWebsite}
        phone={phone}
        loader={loader}
        setLoader={setLoader}
        setPhone={setPhone}
        phoneError={phoneError}
        setPhoneError={setPhoneError}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        setAddress={setAddress}
        address={address}
        officePhone={officePhone}
        setOfficePhone={setOfficePhone}
        city={city}
        setCity={setCity}
        city_id={city_id}
        setCityID={setCityID}
        state={state}
        setState={setState}
        state_id={state_id}
        setStateID={setStateID}
        Country={Country}
        setCountry={setCountry}
        country_id={country_id}
        setCountryID={setCountryID}
        statusMessage={statusMessage}
        setStatusMessage={setStatusMessage}
        statusCode={statusCode}
        setStatusCode={setStatusCode}
      />
    </>
  );
};

export default ClientData;
